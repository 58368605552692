/* eslint-disable no-empty-pattern */
import { createStore } from 'vuex';
import dayjs from 'dayjs';
import i18nLib, { selectedLocale, languages } from '@/plugins/i18n';
import router from '@/plugins/router';
import {
  apiPostLogout,
  apiPostDatasourceAccount,
  apiDeleteDatasourceAccount,
  apiGetTenant,
  apiPostSendSigninEmailLink,
  apiPostIntentQuery,
  apiPostFeedback,
  apiGetPayments,
  apiPostCancelSubscription,
  apiPostPauseSubscription,
  apiPostEmailCardToUser,
  apiPostCollection,
  apiDeleteCollection,
  apiPutCollection,
  apiPutProfile,
  apiGetDataType,
  apiGetData,
  apiGetConversationDefinition,
  apiGetVariableDefinition,
  apiGetFunctions,
  apiDeleteCollectionQuestion,
  apiPostCollectionQuestion,
  apiPutCollectionQuestion,
  apiPostAdminDatasourceRevoke,
  apiDeleteTenantCollectionQuestion,
  apiPostTenantCollectionQuestion,
  apiPostMoveCollectionQuestion,
  apiPutTenantCollectionQuestion,
  apiGetDatasourceAccounts,
  apiPutFlag,
  apiPostDatasourceDefaults,
  apiSetActiveAccount,
  apiGetProjects,
  apiPostProject,
  apiGetProjectData,
  apiDeleteProject,
  apiPostProjectEndpoint,
  apiDeleteProjectVariable,
  apiDeleteProjectEndpoint,
  apiGetUsers,
  apiPutProject,
  apiDeleteProjectSecret,
  apiPostResumeSubscription,
  apiPostUpdateSubscription,
  apiPostUpdatePreviewSubscription,
  apiPostAddUser,
  apiGetDataSourceModels,
  apiGetSuggestions,
  apiGetAddresses,
  apiPutAddress,
  apiChangeSubscriptionAddress,
  apiPutOrganisation,
  apiGetOrganisations,
  apiGetSelectedAccount,
} from '@/helpers/api';
import { SUBSCRIPTION_STATES, MAX_HISTORY_SUGGESTION_ITEMS, BASE_URL, LOGIN_PROVIDERS, DATASOURCE_LOGO } from '@/constants';
import delay from '@/helpers/delay';
import idb from '@/helpers/idb';
import text2speech from '@/helpers/text2speech';

const i18n = i18nLib.global;
window.idb = idb;

const HelpQuestions = [
  { id: 1, category: 1, text: 'How can I change default payment settings?' },
  { id: 2, category: 1, text: "What will happen to my Free account if I don't log in for a long time?" },
  { id: 3, category: 1, text: 'How do I reset my password?' },
  { id: 4, category: 2, text: 'How can I upgrade my subscription plan?' },
  { id: 5, category: 2, text: 'How can I recover my password?' },
  { id: 6, category: 2, text: 'What is AI and natural language programming?' },
  { id: 7, category: 3, text: 'How does Fidsy simplify data analysis for users?' },
  { id: 8, category: 3, text: 'How does Fidsy help maximize ROI?' },
  { id: 9, category: 3, text: 'What makes Fidsy different from other business intelligence tools?' },
  { id: 10, category: 4, text: 'What are the benefits of using Fidsy?' },
  { id: 11, category: 4, text: 'Can I add additional data sources to Fidsy?' },
];

export default createStore({
  state: {
    bootstrapped: false,
    loginProviders: [LOGIN_PROVIDERS.GOOGLE, LOGIN_PROVIDERS.MAGIC_LINK, LOGIN_PROVIDERS.PASSWORD],
    signupEnabled: true,
    showSidePanel: false,
    toastNotifications: [],
    user: {},
    quota: 0,
    features: [],
    org: null,
    organisations: [],
    environment: null,
    timezone: null,
    tenantUpdated: null,
    tenantId: null,
    collections: [],
    availableDataSources: {},
    data_sources: {},
    dataSourcesList: [],
    payments: [],
    subscription: {},
    locale: 'en-GB',
    languages,
    selectedCollectionQuestion: null,
    activeDatasourceType: null,
    activeDatasourceDatasourceId: null,
    activeDatasourceAccountId: null,
    activeDatasourceModelId: null,
    designTimeActiveDatasourceType: null,
    designTimeActiveDatasourceDatasourceId: null,
    designTimeActiveDatasourceAccountId: null,
    designTimeActiveDatasourceModelId: null,
    designTimeLanguage: null,
    showTrialOverModal: false,
    showConversationHelp: false,
    conversationHelpMinimized: false,
    notificationCount: 0,
    conversation: {},
    showFeedbackModal: false,
    text2SpeechActivated: localStorage.getItem('text2speech_activated') === 'true',
    selectedIntentDefinition: {},
    selectedIntentDirty: false,
    selectedIntentDefinitionStepId: null,
    functions: null,
    conversationSuggestions: {},
    designTimeData: {},
    projects: {},
    projectData: {},
    users: [],
    models: [],
    modelsFetched: false,
    addresses: [],
  },
  mutations: {
    SET_BOOTSTRAPPED(state) {
      state.bootstrapped = true;
    },
    ADD_TOAST_MESSAGE(state, payload) {
      // const { title, message, duration } = payload;
      state.toastNotifications.push(payload);
    },
    REMOVE_TOAST_MESSAGE(state, payload) {
      const i = state.toastNotifications.findIndex((n) => payload === n.key);
      clearTimeout(state.toastNotifications[i].timer);
      state.toastNotifications.splice(i, 1);
    },
    SET_SHOW_SIDE_PANEL(state, payload) {
      state.showSidePanel = payload;
    },
    SET_LOCALE(state, payload) {
      state.locale = payload;
      localStorage.setItem('locale', payload);
    },
    SET_CONVERSATION_HELP_VISIBLE(state, payload) {
      state.showConversationHelp = !!payload;
    },
    SET_CONVERSATION_HELP_MINIMIZE(state, payload) {
      state.conversationHelpMinimized = !!payload;
    },
    SET_TRIAL_OVER_MODAL_VISIBLE(state, payload) {
      state.showTrialOverModal = !!payload;
    },
    SET_ACTIVE_DATASOURCE(state, type) {
      if (state.data_sources && type && state.data_sources[type]) {
        if (type) {
          localStorage.setItem(`active-datasource-type-${state.org}`, type);
        }
        state.activeDatasourceType = type;
        return;
      }

      const datasources = state.dataSourcesList.filter((ds) => ds.active && ds.account);
      if (datasources.length > 0) {
        if (datasources[0].type) {
          localStorage.setItem(`active-datasource-type-${state.org}`, datasources[0].type);
        }

        state.activeDatasourceType = datasources[0].type;
        return;
      }

      state.activeDatasourceType = null;
    },
    SET_ACTIVE_DATASOURCE_DATASOURCE_ID(state, payload) {
      if (payload) {
        state.activeDatasourceDatasourceId = payload;
      }
    },
    SET_ACTIVE_DATASOURCE_ACCOUNT_ID(state, payload) {
      if (payload) {
        state.activeDatasourceAccountId = payload;
      }
    },
    SET_ACTIVE_DATASOURCE_MODEL_ID(state, payload) {
      if (payload) {
        state.activeDatasourceModelId = payload;
      }
    },
    SET_DESIGN_TIME_ACTIVE_DATASOURCE(state, type) {
      if (state.availableDataSources && type && state.availableDataSources[type]) {
        state.designTimeActiveDatasourceType = type;
        localStorage.setItem(`design-time-active-datasource-type-${state.org}`, type);
        return;
      }
      state.designTimeActiveDatasourceType = null;
      localStorage.setItem(`design-time-active-datasource-type-${state.org}`, null);
    },
    SET_DESIGN_TIME_ACTIVE_DATASOURCE_DATASOURCE_ID(state, payload) {
      state.designTimeActiveDatasourceDatasourceId = payload;
      localStorage.setItem(`design-time-active-datasource-datasource-id-${state.org}`, payload);
    },
    SET_DESIGN_TIME_ACTIVE_DATASOURCE_ACCOUNT_ID(state, payload) {
      state.designTimeActiveDatasourceAccountId = payload;
      localStorage.setItem(`design-time-active-datasource-account-id-${state.org}`, payload);
    },
    SET_DESIGN_TIME_ACTIVE_DATASOURCE_MODEL_ID(state, payload) {
      payload = payload === 'null' ? null : payload;
      state.designTimeActiveDatasourceModelId = payload;
      localStorage.setItem(`design-time-active-model-id-${state.org}`, payload);
    },
    SET_DESIGN_TIME_LANGUAGE(state, payload) {
      state.designTimeLanguage = payload;
    },
    SET_TENANT_DATASOURCES(state, payload) {
      state.data_sources = payload;
    },
    SET_QUOTA(state, payload) {
      state.quota = payload;
    },
    SET_TENANT(state, payload) {
      const {
        available_datasources,
        subscription = {},
        features = [],
        data_sources = {},
        user = {},
        updated,
        tenant_id,
        flags = {},
        projects = {},
        collections = [],
        timezone = '',
        quota = {},
        environment,
        org,
        organisations,
      } = payload;

      Object.keys(available_datasources).forEach((type) => {
        if (type in DATASOURCE_LOGO) {
          available_datasources[type].logo = DATASOURCE_LOGO[type];
        }
      });
      state.availableDataSources = available_datasources;

      Object.keys(data_sources).forEach((type) => {
        // Remove unselected org's accounts
        Object.keys(data_sources[type]).forEach((id) => {
          Object.keys(data_sources[type][id].accounts).forEach((accountId) => {
            if (data_sources[type][id].accounts[accountId].org !== org) {
              delete data_sources[type][id].accounts[accountId];
            }
          });
        });
        if (!(type in available_datasources)) {
          delete data_sources[type];
        }
      });

      state.data_sources = data_sources;
      state.user = user;
      state.tenantUpdated = updated;
      state.tenantId = tenant_id;
      state.flags = flags;
      state.collections = collections.filter((collection) => collection.org === org);
      state.timezone = timezone;
      state.features = features;
      state.environment = environment;
      state.org = org;
      state.projects = projects;
      state.subscription = subscription[org];
      state.organisations = organisations;

      if (typeof quota[org] === 'object') {
        const { month } = quota[org];
        state.quota = quota[org][month];
      }

      state.dataSourcesList = [];
      const types = Object.keys(state.data_sources);
      for (let i = 0, { length } = types; i < length; i++) {
        const datasource = state.data_sources[types[i]];
        state.dataSourcesList.push(datasource);
      }
    },
    SET_TENANT_TIMEZONE(state, payload) {
      state.timezone = payload;
    },
    LOGOUT(state) {
      state.user = {};
      state.data_sources = {};
      state.dataSourcesList = [];
      state.activeDatasourceType = null;

      localStorage.removeItem('xsrf-token');
    },
    ADD_QUERY(state, payload) {
      if (!(payload.created in state.conversation)) {
        state.conversation[payload.created] = {};
      }
      state.conversation[payload.created].query = payload;
      state.conversation[payload.created].loading = true;
    },
    SET_QUERY(state, payload) {
      const { query, response, loading } = payload;

      state.conversation[query.created].loading = loading;

      if (response?.error) {
        state.conversation[query.created].error = response.message;
      } else {
        state.conversation[query.created].responses = response.responses;
      }
    },
    SET_QUERY_IS_LOADING(state, payload) {
      const { query, loading } = payload;
      state.conversation[query.created].loading = !!loading;
    },
    SET_FEEDBACK_MODAL_VISIBLE(state, payload) {
      state.showFeedbackModal = payload;
    },
    SET_PAYMENTS(state, payload) {
      state.payments = payload;
    },
    SET_TEXT2SPEECH_ACTIVATE(state, payload) {
      state.text2SpeechActivated = payload;
      localStorage.setItem('text2speech_activated', payload);

      if (!payload) {
        text2speech.stopSpeaking();
      }
    },
    SET_DESIGN_TIME_DATA(state, { type, data }) {
      state.designTimeData[type] = data;
    },
    SET_COLLECTION_QUESTION(state, payload) {
      const index = state.designTimeData[state.designTimeActiveDatasourceType].questions.findIndex((q) => q.id === payload.id);
      if (index > -1) {
        state.designTimeData[state.designTimeActiveDatasourceType].questions[index] = payload;
      } else {
        state.designTimeData[state.designTimeActiveDatasourceType].questions.push(payload);
      }
    },
    SET_COLLECTION_SELECTED_CHART_TYPE(state, payload) {
      const { collectionId, id, selectedChartType } = payload;
      const collection = state.collections.find((q) => q.id === collectionId);
      const question = collection?.questions.find((q) => q.id === id);
      if (question) {
        question.selectedChartType = selectedChartType;
      }
    },
    REMOVE_COLLECTION_QUESTION(state, payload) {
      const index = state.designTimeData[state.designTimeActiveDatasourceType].questions.findIndex((q) => q.name === payload);
      state.designTimeData[state.designTimeActiveDatasourceType].questions.splice(index, 1);
    },
    REMOVE_COLLECTION_VARIABLE(state, payload) {
      delete state.designTimeData[state.designTimeActiveDatasourceType].variables[payload];
    },
    ADD_CONVERSATION_STEP(state, payload) {
      const { type, flow, stepItem } = payload;
      if (!state.designTimeData[type].conversations[flow].steps.some((s) => s.id === stepItem.id)) {
        state.designTimeData[type].conversations[flow].steps.push(stepItem);
      }
    },
    ADD_COLLECTION_STEP(state, payload) {
      const { type, question, stepItem } = payload;
      const index = state.designTimeData[state.designTimeActiveDatasourceType].questions.findIndex((q) => q.name === question);
      if (!state.designTimeData[type].questions[index].steps.some((s) => s.id === stepItem.id)) {
        state.designTimeData[type].questions[index].steps.push(stepItem);
      }
    },
    ADD_PROJECT_STEP(state, payload) {
      const { projectId, endpointId, stepItem } = payload;
      if (!state.projectData[projectId].endpoints[endpointId].steps.some((s) => s.id === stepItem.id)) {
        state.projectData[projectId].endpoints[endpointId].steps.push(stepItem);
      }
    },
    SET_SELECTED_INTENT_DEFINITION(state, payload) {
      state.selectedIntentDefinition = payload;
      state.selectedIntentDirty = false;
    },
    SET_SELECTED_INTENT_DEFINITION_PROP(state, payload) {
      const { prop, value } = payload;
      state.selectedIntentDefinition[prop] = value;
      state.selectedIntentDirty = true;
    },
    SET_SELECTED_INTENT_DEFINITION_STEP(state, payload) {
      const index = state.selectedIntentDefinition.steps.findIndex((s) => s.id === payload.id);
      state.selectedIntentDefinition.steps[index] = payload;
      state.selectedIntentDirty = true;
    },
    SET_SELECTED_INTENT_DEFINITION_STEP_POSITION(state, payload) {
      const { id, column, order } = payload;
      const index = state.selectedIntentDefinition.steps.findIndex((s) => s.id === id);
      if (index > -1) {
        state.selectedIntentDefinition.steps[index].column = column;
        state.selectedIntentDefinition.steps[index].order = order;
        state.selectedIntentDirty = true;
      }
    },
    ADD_SELECTED_INTENT_DEFINITION_STEP(state, payload) {
      if (!state.selectedIntentDefinition.steps.some((s) => s.id === payload.id)) {
        state.selectedIntentDefinition.steps.push(payload);
        state.selectedIntentDirty = true;
      }
    },
    REMOVE_SELECTED_INTENT_DEFINITION_STEP(state, payload) {
      const index = state.selectedIntentDefinition.steps.findIndex((s) => s.id === payload);
      state.selectedIntentDefinition.steps.splice(index, 1);
      state.selectedIntentDirty = true;
    },
    SET_SELECTED_INTENT_DIRTY(state, payload) {
      state.selectedIntentDirty = payload;
    },
    SET_SELECTED_INTENT_DEFINITION_STEP_ID(state, payload) {
      state.selectedIntentDefinitionStepId = payload;
    },
    SET_FUNCTIONS(state, payload) {
      state.functions = payload;
    },
    SET_CONVERSATION_SUGGESTIONS(state, payload = {}) {
      const { type, modelId } = payload;
      state.conversationSuggestions[`${type}-${modelId}`] = payload;
    },
    SET_PROJECTS(state, payload) {
      state.projects = payload;
    },
    SET_PROJECT_DATA(state, payload) {
      state.projectData[payload.project_id] = payload;
    },
    REMOVE_PROJECT(state, payload) {
      delete state.projects[payload];
      delete state.projectData[payload];
    },
    SET_USERS(state, payload) {
      if (Array.isArray(payload)) {
        state.users = payload;
      }
    },
    SET_INTENT_RESPONSE_TEXT(state, payload) {
      const { query, speakText, responseIndex } = payload;
      state.conversation[query.created].responses[responseIndex].text = speakText;
    },
    SET_INTENT_RESPONSE_CARD_TEXT(state, payload) {
      const { query, speakText, responseIndex } = payload;
      state.conversation[query.created].responses[responseIndex].card.text = speakText;
    },
    SET_MODELS(state, payload) {
      state.models = payload;
      state.models.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    },
    SET_MODELS_FETCHED(state, payload) {
      state.modelsFetched = payload;
    },
    ADD_MODEL(state, payload) {
      const index = state.models.findIndex((model) => model.model_id === payload.model_id);
      if (index > -1) {
        state.models[index] = payload;
      } else {
        state.models.push(payload);
      }
      state.models.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    },
    SET_SELECTED_COLLECTION_QUESTION(state, payload) {
      state.selectedCollectionQuestion = payload;
    },
    SET_ADDRESSES(state, payload) {
      state.addresses = payload;
    },
    UPDATE_ADDRESS(state, payload) {
      const id = payload.address_id || payload.id;
      const index = state.addresses.findIndex((a) => a.id === id);
      if (index < 0) return;
      if (payload.status === 'archived') {
        state.addresses.splice(index, 1);
      } else {
        state.addresses[index] = { id, ...payload };
      }
    },
    SET_ORGANISATION(state, payload) {
      state.org = payload;
    },
    CLEAR_CONVERSATION(state) {
      state.conversation = {};
    },
  },
  actions: {
    changeLocale({ commit }, payload) {
      i18n.locale = payload;
      commit('SET_LOCALE', payload);
    },
    showToastMessage({ commit }, payload) {
      let args = payload;
      // TODO: remove this after indexeddb fix
      if (payload.type === 'error' && window.location.hostname !== 'local.fidsy.cloud') {
        return;
      }
      if (typeof args === 'string') {
        args = { message: args };
      }
      if (args.message) {
        args.message = args.message.substring(0, 150);
      }

      args.key = new Date().getTime();
      args.duration = args.duration || 4500;
      args.type = args.type || 'default';
      // Set a timer to kill the notification after the specified duration
      args.timer = setTimeout(() => {
        commit('REMOVE_TOAST_MESSAGE', args.key);
      }, args.duration);
      commit('ADD_TOAST_MESSAGE', args);
      if (payload.type === 'error') {
        console.error(args);
      }
    },
    closeToastMessage({ commit }, payload) {
      commit('REMOVE_TOAST_MESSAGE', payload);
    },
    setActiveAccount({ commit }, type) {
      commit('SET_ACTIVE_DATASOURCE', type);
    },
    async addAccountToDatasource({ dispatch }, { type, datasource_id, account, showSuccessMessage = true }) {
      if (!type || !datasource_id || !account) {
        dispatch('showToastMessage', { title: i18n.t('datastore.datastore'), type: 'error' });
      } else {
        try {
          const response = await apiPostDatasourceAccount({ type, datasource_id, account });

          if (response.status === 200) {
            await dispatch('getTenant');

            if (showSuccessMessage) {
              dispatch('showToastMessage', { message: i18n.t('datastore.saved_successfully'), type: 'success' });
            }
            return Promise.resolve(true);
          }
          dispatch('showToastMessage', { title: i18n.t('settings.failed_to_update_account'), type: 'error' });
        } catch (e) {
          dispatch('showToastMessage', { title: i18n.t('settings.failed_to_update_account'), type: 'error' });
        }
      }
      return Promise.resolve(false);
    },
    async removeAccountFromDatasource({ dispatch }, { type, datasource_id, account_id }) {
      if (!type || !datasource_id || !account_id) {
        dispatch('showToastMessage', { title: i18n.t('datastore.invalid_configuration'), type: 'error' });
      } else {
        try {
          const response = await apiDeleteDatasourceAccount({ type, datasource_id, account_id });

          if (response.status === 200) {
            await dispatch('getTenant');

            dispatch('showToastMessage', { message: i18n.t('datastore.removed_successfully'), type: 'success' });
            return Promise.resolve(true);
          }
          dispatch('showToastMessage', { title: i18n.t('datastore.failed_to_remove_account'), type: 'error' });
        } catch (e) {
          dispatch('showToastMessage', { title: i18n.t('datastore.failed_to_remove_account'), type: 'error' });
        }
      }
      return Promise.resolve(false);
    },
    async query({}, payload) {
      const response = await apiPostIntentQuery(payload);

      if (response.status === 200) {
        // Change chart name
        const responses = response?.data?.responses || [];
        responses?.forEach((res) => {
          if (res?.card?.chart) {
            res.card.chart.forEach((chart) => {
              if (chart.chart.type === 'bar' && chart.chart?.stacked) {
                chart.chart.type = 'bar-stacked';
              }
            });
          }
        });
      }

      return response;
    },
    async queryIntent({ state, dispatch, commit }, { phrase, event }) {
      try {
        const query = {
          created: Date.now(),
          phrase,
          event,
        };

        commit('ADD_QUERY', query);

        const scrollContainer = document.getElementById('conversation-container');
        scrollContainer?.scrollBy({ left: 0, top: scrollContainer.scrollHeight + 100, behavior: 'smooth' });

        const response = await dispatch('query', query);

        if (response.status === 200) {
          const { quota, selected_account } = response.data;

          commit('SET_QUOTA', quota);
          commit('SET_QUERY', { query, response: response.data, loading: false });
          localStorage.setItem('tenant', state.tenantId);
          await idb.addConversationHistory(state.org, `${selected_account.type}-${selected_account.properties.MODEL_ID}`, query, response.data);

          await delay(100);
          scrollContainer?.scrollBy({ left: 0, top: scrollContainer.scrollHeight + 200, behavior: 'smooth' });
        } else if (response.status === 400) {
          commit('SET_QUERY', { query, response: response.data, loading: false });
        } else if (response.status === 500) {
          response.data.message = 'Well, this is embarrassing... Sorry, this is not working properly. We now know about this mistake and are working to fix it.';
          commit('SET_QUERY', { query, response: response.data, loading: false });
        }

        if (response.data.selected_account) {
          const { selected_account } = response.data;
          if (selected_account) {
            commit('SET_ACTIVE_DATASOURCE_ACCOUNT_ID', selected_account.account_id);
            commit('SET_ACTIVE_DATASOURCE_DATASOURCE_ID', selected_account.datasource_id);
            commit('SET_ACTIVE_DATASOURCE', selected_account.type);
            if (selected_account?.properties?.MODEL_ID) {
              commit('SET_ACTIVE_DATASOURCE_MODEL_ID', selected_account.properties.MODEL_ID);
            }
          }
        }

        if (state.text2SpeechActivated && response.data.responses && response.data.responses.length) {
          response.data.responses.forEach((res) => {
            if (res.text && typeof res.text === 'string') {
              text2speech.speak(res.text);
            } else if (res?.card?.text) {
              text2speech.speak(res.card.text);
            }
          });
        }

        return {
          query,
          queryData: response.data,
        };
      } catch (e) {
        dispatch('showToastMessage', { title: e.message, type: 'error' });
        return false;
      }
    },
    async fetchConversationHistory({ state, commit }, payload = {}) {
      const { start = null, total = 4, clearHistory = false } = payload;
      if (clearHistory) {
        commit('CLEAR_CONVERSATION');
      }

      if (!state.activeDatasourceType) return;
      const res = await idb.getConversationHistory(state.org, `${state.activeDatasourceType}-${state.activeDatasourceModelId}`, start, total);
      Object.values(res)
        .reverse()
        .forEach((item) => {
          commit('ADD_QUERY', item.query);
          commit('SET_QUERY', { query: item.query, response: item.queryData });

          if (item.queryData.selected_account && !state.activeDatasourceAccountId) {
            const { selected_account = {} } = item.queryData;
            const { account_id, datasource_id, type } = selected_account;
            commit('SET_ACTIVE_DATASOURCE_ACCOUNT_ID', account_id);
            commit('SET_ACTIVE_DATASOURCE_DATASOURCE_ID', datasource_id);
            commit('SET_ACTIVE_DATASOURCE', type);
            if (selected_account?.properties?.MODEL_ID) {
              commit('SET_ACTIVE_DATASOURCE_MODEL_ID', selected_account.properties.MODEL_ID);
            }
          }
        });
      //
      // const lastTime = Math.max(...Object.keys(state.conversation).map((i) => parseInt(i, 10)));
      // if (lastTime && Number.isInteger(lastTime) && dayjs().diff(dayjs(lastTime), 'day') > 0) {
      //   await idb.deleteDatabase();
      //   commit('CLEAR_CONVERSATION');
      // }
    },
    async getTenant({ state, commit, dispatch, getters }, payload = {}) {
      const { getSelectedAccount = false } = payload;
      try {
        const response = await apiGetTenant();

        if (response.status === 200) {
          if (state.tenantUpdated !== null) {
            if (state.tenantUpdated > response.data.updated) {
              await delay();
              return await dispatch('getTenant');
            }
          }
          commit('SET_TENANT', response.data);

          if (getSelectedAccount) {
            await dispatch('getSelectedAccount');
          }

          if (response.data.user.locale) {
            dispatch('changeLocale', response.data.user.locale);
          }
          commit('SET_BOOTSTRAPPED');

          if (!getters.getRegistrationComplete || !getters.getOnboardingComplete) {
            router.push({
              path: '/onboarding',
              query: router.currentRoute.value.query,
            });
            return Promise.resolve('routeChanged');
          }

          // if (state.dataSourcesList.length > 0) {
          //   const type = Object.keys(state.data_sources)[0];
          //   const accounts = Object.values(state.data_sources[type])[0]?.accounts || {};
          //   if (!Object.keys(accounts).length) {
          //     router.push('/');
          //   }
          // } else {
          //   router.push('/');
          // }

          commit('SET_DESIGN_TIME_ACTIVE_DATASOURCE', localStorage.getItem(`design-time-active-datasource-type-${state.org}`));
          commit('SET_DESIGN_TIME_ACTIVE_DATASOURCE_ACCOUNT_ID', localStorage.getItem(`design-time-active-datasource-account-id-${state.org}`));
          commit('SET_DESIGN_TIME_ACTIVE_DATASOURCE_DATASOURCE_ID', localStorage.getItem(`design-time-active-datasource-datasource-id-${state.org}`));
          commit('SET_DESIGN_TIME_ACTIVE_DATASOURCE_MODEL_ID', localStorage.getItem(`design-time-active-model-id-${state.org}`));

          if (!response.data.timezone) {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            dispatch('updateTimeZone', timezone);
          }
        }
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(new Error('Failed to retrieve Tenant'));
      }
    },
    async getSelectedAccount({ commit, dispatch }) {
      try {
        const response = await apiGetSelectedAccount();
        if (response.status === 200) {
          const { account_id, datasource_id, type, properties = {} } = response?.data;
          commit('SET_ACTIVE_DATASOURCE_ACCOUNT_ID', account_id);
          commit('SET_ACTIVE_DATASOURCE_DATASOURCE_ID', datasource_id);
          commit('SET_ACTIVE_DATASOURCE', type);
          if (properties?.MODEL_ID) {
            commit('SET_ACTIVE_DATASOURCE_MODEL_ID', properties.MODEL_ID);
          }
        } else {
          dispatch('showToastMessage', { title: response.data.message || i18n.t('datastore.failed_to_fetch_selected_account'), type: 'error' });
        }
      } catch {
        dispatch('showToastMessage', { title: i18n.t('datastore.failed_to_fetch_selected_account'), type: 'error' });
      }
    },
    async fetchDatasourceAccounts({ dispatch }, { type, datasource_id }) {
      try {
        const response = await apiGetDatasourceAccounts(type, datasource_id);
        if (response.status === 200) {
          return response.data;
        }

        if (response.data && response.data.error) {
          await dispatch('getTenant');
          dispatch('showToastMessage', { title: response.data.message || i18n.t('datastore.failed_to_fetch_accounts'), type: 'error' });
        }

        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('datastore.failed_to_fetch_accounts'), type: 'error' });
        return false;
      }
    },
    async revokeDataSourceAccess({ commit, dispatch }, { type, datasource_id }) {
      try {
        if (!type) {
          return;
          // TODO: Handler parameter error
        }

        const response = await apiPostAdminDatasourceRevoke({ type, datasource_id });

        if (response.status === 200) {
          commit('SET_TENANT', response.data);

          await dispatch('getTenant');

          dispatch('showToastMessage', { message: i18n.t('datastore.access_revoked'), type: 'success' });
        } else if (response.data && response.data.message) {
          dispatch('showToastMessage', { title: response.data.message, type: 'error' });
        }
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('datastore.failed_to_revoke_account'), type: 'error' });
      }
    },
    async sendSigninEmailLink(store, { email, token }) {
      try {
        return apiPostSendSigninEmailLink({ email, token });
      } catch (e) {
        return { status: 500 };
      }
    },
    async bootstrap({ dispatch, state, commit }) {
      // let isAssistant = false;
      let xsrfToken = localStorage.getItem('xsrf-token');
      const locale = localStorage.getItem('locale') || selectedLocale;
      dispatch('changeLocale', locale);

      // Make sure router is loaded
      await router.isReady();
      const { path, query, name } = router.currentRoute.value;

      if (name === 'password-reset') {
        dispatch('removeSvgLoader');
      } else if (path === '/login' || window.location.pathname === '/login') {
        if (!xsrfToken && query.xsrfToken) {
          ({ xsrfToken } = query);
          localStorage.setItem('xsrf-token', xsrfToken);
        }
        if (query.reset) {
          await dispatch('getTenant', { getSelectedAccount: true });
          dispatch('removeSvgLoader');
          return;
        }

        if (xsrfToken) {
          try {
            const response = await dispatch('getTenant', { getSelectedAccount: true });
            if (response === 'routeChanged') {
              await idb.deleteDatabase();
              dispatch('removeSvgLoader');
              return;
            }

            if (localStorage.getItem('tenant') !== state.tenantId) {
              localStorage.removeItem('xsrf-token');
              localStorage.removeItem('launchRequest');
              localStorage.removeItem('tenant');
              localStorage.removeItem('text2speech_activated');

              Object.keys(localStorage).forEach((key) => {
                if (key.startsWith('suggestions-history') || key.startsWith('active-datasource') || key.startsWith('design-time-active')) {
                  localStorage.removeItem(key);
                }
              });
              await idb.deleteDatabase();
              commit('CLEAR_CONVERSATION');
            }
            router.push('/');
          } catch (error) {
            console.log('GET TENANT ERROR', error); // eslint-disable-line
          }
        }
        dispatch('removeSvgLoader');
      } else if (path === '/authcallback') {
        // Extract query parameters
        const { status, message, redirect, type } = router.currentRoute.value.query;

        if (status && message && redirect) {
          if (decodeURIComponent(redirect) !== '/login') {
            router.push({
              path: decodeURIComponent(redirect),
              query: { type },
            });
            await dispatch('getTenant', { getSelectedAccount: true });
            dispatch('removeSvgLoader');
            dispatch('showToastMessage', { message, type: status });

            if (type && redirect === '/user/datasources') {
              // TODO: open account modal
              // if (state.availableDataSources?.[type]?.accounts && state.data_sources?.[type]) {
              //   const datasource_id = Object.keys(state.data_sources[type])?.[0];
              //   if (datasource_id) {
              //     this.$showModal(PropertySelector, {
              //       datasourceId: datasource_id,
              //       type,
              //     });
              //   }
              // }
            }

            return;
          }
          dispatch('showToastMessage', { message, type: status });
        }

        router.push('/login');
        dispatch('removeSvgLoader');
      } else {
        if (xsrfToken) {
          try {
            await dispatch('getTenant', { getSelectedAccount: true });
          } catch (error) {
            router.push('/login');
            console.log('GET TENANT ERROR', error); // eslint-disable-line
          }
        } else {
          router.push('/login');
        }
        dispatch('removeSvgLoader');
      }
    },
    async updateProfile({ commit }, payload) {
      try {
        const response = await apiPutProfile(payload);
        if (response.status === 200) {
          commit('SET_TENANT', response.data);
          return response.data;
        }
        return response.data;
      } catch (e) {
        return false;
      }
    },
    removeSvgLoader() {
      const svgLoader = document.getElementById('svg_loading');
      if (svgLoader) {
        svgLoader.remove();
      }
      return Promise.resolve();
    },
    async logout({ commit }, config = {}) {
      const { clearIdb } = config;
      try {
        await apiPostLogout();
      } catch (e) {
        // TODO: Handle error
      }

      commit('LOGOUT');

      if (clearIdb) {
        try {
          await idb.deleteDatabase();
        } catch (e) {
          // TODO: Handle error
        }
      }

      window.location = '/login';
    },
    deleteTenant() {
      // TODO
    },
    // eslint-disable-next-line
    async oAuthRedirect({ commit, dispatch }, { type, onSuccess }) {
      let redirectUrl = `${BASE_URL}/api/v1/datasourceredirect?type=${type}`;

      if (window.location.host === 'local.fidsy.cloud') {
        redirectUrl += '&local=true';
      }

      window.location = redirectUrl;
      // popupWindow = window.open(redirectUrl, 'Fidsy Add Datasource Connection', 'menubar=no,location=yes,resizable=no,titlebar=no,scrollbars=yes,status=no,width=800,height=600');

      // if (popupWindow) {
      //   popupWindowMessageEventListener = async (event) => {
      //     // Do we trust the sender of this message?  (might be
      //     // different from what we originally opened, for example).
      //     if (event.origin !== window.location.origin) return;

      //     const { source, payload } = event.data;

      //     if (!source || source !== 'fidsy_popup_message' || !payload) return;

      //     const { status, message } = payload;
      //     if (status && message) {
      //       dispatch('showToastMessage', { message, type: status });
      //       if (status === 'success') {
      //         await dispatch('getTenant');
      //         if (onSuccess) onSuccess();
      //       }
      //     }
      //   };

      //   window.addEventListener('message', popupWindowMessageEventListener, false);

      //   const interval = setInterval(() => {
      //     if (popupWindow.closed) {
      //       clearInterval(interval);
      //       window.removeEventListener('message', popupWindowMessageEventListener);
      //       popupWindowMessageEventListener = null;
      //     }
      //   }, 1000);
      // }
    },
    exportToCsv(store, table) {
      let data;

      const [firstRow] = table;
      const columnNames = Object.keys(firstRow).map((item) => item.trim());

      const c = [];
      columnNames.forEach((cell) => {
        if (cell.endsWith('__%')) {
          c.push(`${cell.slice(0, -3)} %`);
        } else if (cell.endsWith('__change')) {
          c.push(`${cell.slice(0, -3)} Change %`);
        } else if (cell.startsWith('Previous_')) {
          c.push(`Previous ${cell.substring(2)}`);
        } else {
          c.push(cell);
        }
      });
      data = `${c.join(',')}\n`;

      if (table) {
        table.forEach((row) => {
          const r = [];
          columnNames.forEach((columnName) => {
            if (columnName in row) {
              r.push(row[columnName]);
            }
          });
          data += `${r.join(',')}\n`;
        });
      }
      const csvContent = `data:text/csv;charset=utf-8,${data}`;

      const encodedUri = encodeURI(csvContent);

      const link = document.getElementById('export');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `fidsy_card_data_${new Date().toISOString().substring(0, 10)}.csv`);
      link.target = '_blank';
      link.click();

      link.setAttribute('href', '');
      link.setAttribute('download', '');
    },
    async emailCardToUser({ dispatch }, data) {
      try {
        const response = await apiPostEmailCardToUser(data);

        if (response.status === 200) {
          dispatch('showToastMessage', { message: i18n.t('collections.email_sent'), type: 'success' });
          return;
        }
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_send_email'), type: 'error' });
      } catch (err) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_send_email'), type: 'error' });
      }
    },
    async sendFeedback({ dispatch }, payload) {
      try {
        const { sentiment, message, fields, data, phrase, hideNotification } = payload; // eslint-disable-line

        const response = await apiPostFeedback({ sentiment, message, fields, data, phrase });

        if (response.status === 200) {
          if (!hideNotification) {
            dispatch('showToastMessage', { message: i18n.t('feedback.submitted_successfully'), type: 'success' });
          }
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('feedback.failed_to_send'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('feedback.failed_to_send'), type: 'error' });
        return false;
      }
    },
    async fetchPayments({ commit, dispatch }) {
      try {
        const response = await apiGetPayments();

        if (response.status === 200) {
          commit('SET_PAYMENTS', response.data || []);
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('subscription.failed_to_get_payments'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_get_payments'), type: 'error' });
        return false;
      }
    },
    async cancelSubscription({ dispatch }) {
      try {
        const response = await apiPostCancelSubscription();

        if (response.status === 200 && response.data.success) {
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('subscription.failed_to_cancel'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_cancel'), type: 'error' });
        return false;
      }
    },
    async pauseSubscription({ state, dispatch }, payload) {
      try {
        const { type } = payload;
        const subscriptionId = state.data_sources[type].subscription.subscription_id;

        const response = await apiPostPauseSubscription({ type, subscription_id: subscriptionId, paused: true });

        return !!(response.status === 200 && response.data.success);
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_pause'), type: 'error' });
        return false;
      }
    },
    async continueSubscription({ state, dispatch }, payload) {
      try {
        const { type } = payload;
        const subscriptionId = state.data_sources[type].subscription.subscription_id;

        const response = await apiPostPauseSubscription({ type, subscription_id: subscriptionId, paused: false });

        if (response.status === 200 && response.data.success) {
          return true;
        }
        dispatch('showToastMessage', { title: response.error.message || i18n.t('subscription.failed_to_continue'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_continue'), type: 'error' });
        return false;
      }
    },
    async resumeSubscription({ dispatch }) {
      try {
        const response = await apiPostResumeSubscription();

        if (response.status === 200 && response.data.success) {
          return true;
        }
        dispatch('showToastMessage', { title: response.error.message || i18n.t('subscription.failed_to_resume'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_resume'), type: 'error' });
        return false;
      }
    },
    async updateSubscription({ dispatch }, payload) {
      try {
        const { priceId } = payload;
        const response = await apiPostUpdateSubscription({ price_id: priceId });

        if (response.status === 200 && response.data.success) {
          return true;
        }
        dispatch('showToastMessage', { title: response.error.message || i18n.t('subscription.failed_to_update'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_update'), type: 'error' });
        return false;
      }
    },
    async updatePreviewSubscription({ dispatch }, payload) {
      try {
        const { priceId } = payload;
        const response = await apiPostUpdatePreviewSubscription({ price_id: priceId });

        if (response.status === 200) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('subscription.failed_to_get_pricing_preview'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('subscription.failed_to_get_pricing_preview'), type: 'error' });
        return false;
      }
    },
    async createCollection({ commit, dispatch }, payload) {
      try {
        const response = await apiPostCollection(payload);

        if (response.status === 200) {
          if (response?.data?.tenant) {
            commit('SET_TENANT', response.data.tenant);
          } else {
            dispatch('getTenant');
          }
          return response?.data?.collection;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_create_collection'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_create_collection'), type: 'error' });
        return false;
      }
    },
    async updateCollection({ commit, dispatch }, payload) {
      try {
        const response = await apiPutCollection(payload);

        if (response.status === 200) {
          if (response?.data) {
            commit('SET_TENANT', response.data);
          } else {
            dispatch('getTenant');
          }
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_update_collection'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_update_collection'), type: 'error' });
        return false;
      }
    },
    async deleteCollection({ commit, dispatch }, payload) {
      try {
        const response = await apiDeleteCollection(payload);

        if (response.status === 200) {
          if (response?.data) {
            commit('SET_TENANT', response.data);
          } else {
            dispatch('getTenant');
          }
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_remove_collection'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_remove_collection'), type: 'error' });
        return false;
      }
    },
    async moveCollectionQuestion({ commit, dispatch }, payload) {
      try {
        const response = await apiPostMoveCollectionQuestion(payload);

        if (response.status === 200) {
          commit('SET_TENANT', response.data);
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_move_question'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_move_question'), type: 'error' });
        return false;
      }
    },
    async addTenantCollectionQuestion({ commit, dispatch }, payload) {
      try {
        const response = await apiPostTenantCollectionQuestion(payload);

        if (response.status === 200) {
          commit('SET_TENANT', response.data.tenant);
          dispatch('showToastMessage', { message: i18n.t('collections.question_added'), type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_add_collection'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_add_collection'), type: 'error' });
        return false;
      }
    },
    async removeTenantQuestionFromCollection({ commit, dispatch }, payload) {
      const { collection_id, id } = payload;

      try {
        const response = await apiDeleteTenantCollectionQuestion({ collection_id, id });

        if (response.status === 200) {
          commit('SET_TENANT', response.data);
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_remove_question'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_remove_question'), type: 'error' });
        return false;
      }
    },
    async updateTenantQuestion({ commit, dispatch }, payload = {}) {
      try {
        const response = await apiPutTenantCollectionQuestion(payload);

        if (response.status === 200) {
          commit('SET_TENANT', response.data);
          return true;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_update_question'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_update_question'), type: 'error' });
        return false;
      }
    },
    async fetchConversationIntentDefinition({ dispatch, state }, { name, type }) {
      try {
        const response = await apiGetConversationDefinition({ name, type, model_id: state.designTimeActiveDatasourceModelId });

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_get_conversation'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_get_conversation'), type: 'error' });
        return false;
      }
    },
    async fetchVariableDefinition({ dispatch, state }, { name, type }) {
      try {
        const response = await apiGetVariableDefinition({ name, type, model_id: state.designTimeActiveDatasourceModelId });

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_get_variable'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_get_variable'), type: 'error' });
        return false;
      }
    },
    async fetchDataType({ dispatch, state }, { name, type }) {
      try {
        const response = await apiGetDataType({ name, type, model_id: state.designTimeActiveDatasourceModelId });

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_get_data_type'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_get_data_type'), type: 'error' });
        return false;
      }
    },
    async fetchDesignTimeData({ state, commit, dispatch }, payload = {}) {
      try {
        let { type } = payload;

        if (!type) {
          type = state.designTimeActiveDatasourceType;
        }
        const response = await apiGetData({ type, model_id: state.designTimeActiveDatasourceModelId });

        if (response.status === 200 && response.data) {
          commit('SET_DESIGN_TIME_DATA', { type, data: response.data });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_get_conversations_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_get_conversations_data'), type: 'error' });
        return false;
      }
    },
    async deleteCollectionQuestion({ dispatch, commit, state }, { name, type }) {
      try {
        const response = await apiDeleteCollectionQuestion({ name, type, model_id: state.designTimeActiveDatasourceModelId });

        if (response.status === 200) {
          commit('REMOVE_COLLECTION_QUESTION', name);
          return response;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_remove_question'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_remove_question'), type: 'error' });
        return false;
      }
    },
    async addCollectionQuestion({ dispatch, commit, state }, payload) {
      try {
        const response = await apiPostCollectionQuestion({
          type: state.designTimeActiveDatasourceType,
          model_id: state.designTimeActiveDatasourceModelId,
          ...payload,
        });

        if (response.status === 200) {
          commit('SET_COLLECTION_QUESTION', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_create_question'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_create_question'), type: 'error' });
        return false;
      }
    },
    async setCollectionQuestion({ dispatch, commit, state }, payload) {
      try {
        const response = await apiPutCollectionQuestion({
          type: state.designTimeActiveDatasourceType,
          model_id: state.designTimeActiveDatasourceModelId,
          ...payload,
        });

        if (response.status === 200) {
          commit('SET_COLLECTION_QUESTION', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('collections.failed_to_update_question'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('collections.failed_to_update_question'), type: 'error' });
        return false;
      }
    },
    async fetchFunctions({ state, commit, dispatch }) {
      try {
        const response = await apiGetFunctions(state.designTimeActiveDatasourceType, state.designTimeActiveDatasourceModelId);

        if (response.status === 200 && response.data) {
          commit('SET_FUNCTIONS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('flow.failed_to_fet_functions'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('flow.failed_to_fet_functions'), type: 'error' });
        return false;
      }
    },
    async completeOnboarding({ commit, dispatch }, { accountId, datasource_id, type, modelId }) {
      try {
        const response = await dispatch('setFlag', { key: 'oc', value: true });
        if (response) {
          commit('SET_ACTIVE_DATASOURCE_ACCOUNT_ID', accountId);
          commit('SET_ACTIVE_DATASOURCE_DATASOURCE_ID', datasource_id);
          commit('SET_ACTIVE_DATASOURCE', type);
          commit('SET_ACTIVE_DATASOURCE_MODEL_ID', modelId);
          dispatch('fetchLaunchRequest');
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    async setFlag({ dispatch, commit }, payload) {
      try {
        const response = await apiPutFlag(payload);

        if (response.status === 200) {
          commit('SET_TENANT', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('failed_to_update_flag'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('failed_to_update_flag'), type: 'error' });
        return false;
      }
    },
    async setDatasourceDefaults({ dispatch, commit }, payload) {
      try {
        const { type, datasource_id, account_id } = payload;
        const response = await apiPostDatasourceDefaults({ type, datasource_id, account_id });

        if (response.status === 200) {
          commit('SET_TENANT', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('datastore.failed_to_update_defaults'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('datastore.failed_to_update_defaults'), type: 'error' });
        return false;
      }
    },
    async setActiveConversationAccount({ dispatch, commit }, payload) {
      try {
        const response = await apiSetActiveAccount(payload);
        if (response.status === 200) {
          const { selected_account } = response?.data;
          commit('SET_ACTIVE_DATASOURCE_ACCOUNT_ID', selected_account.account_id);
          commit('SET_ACTIVE_DATASOURCE_DATASOURCE_ID', selected_account.datasource_id);
          commit('SET_ACTIVE_DATASOURCE', selected_account.type);
          if (selected_account?.properties?.MODEL_ID) {
            commit('SET_ACTIVE_DATASOURCE_MODEL_ID', selected_account.properties.MODEL_ID);
          }
          dispatch('showToastMessage', { title: `Active account changed to ${selected_account.name}`, type: 'success' });
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('datastore.account_selection_failed'), type: 'error' });
        return false;
      } catch {
        dispatch('showToastMessage', { title: i18n.t('datastore.account_selection_failed'), type: 'error' });
        return false;
      }
    },
    async fetchConversationSuggestions({ state, commit }, { force = false }) {
      try {
        const type = state.activeDatasourceType;
        const modelId = state.activeDatasourceModelId;
        if (!state.activeDatasourceType) {
          return;
        }
        if (!force && state.conversationSuggestions?.[`${type}-${modelId}`]?.lang === state.user.language) {
          return;
        }

        let suggestions = [];
        let history = [];

        const response = await apiGetSuggestions(state.activeDatasourceType, state.activeDatasourceModelId);

        if (response.status === 200) {
          const resSuggestions = response.data?.suggestions?.[state.user.language] || [];
          suggestions = [...resSuggestions];
        }

        try {
          const record = JSON.parse(localStorage.getItem(`suggestions-history-${type}-${state.activeDatasourceModelId}`));
          if (record && record.type && record.history) {
            const dbHistory = record && Array.isArray(record.history) ? record.history : [];
            history = [...dbHistory];
          }
        } catch {
          history = [];
        }

        const updated = {
          type,
          modelId,
          suggestions,
          history,
          lang: state.user.language,
        };
        commit('SET_CONVERSATION_SUGGESTIONS', updated);
      } catch (e) {
        console.error(e); // eslint-disable-line
      }
    },
    async addToSuggestionHistory({ state, commit }, payload) {
      if (!payload) {
        return;
      }
      try {
        const type = state.activeDatasourceType;
        const modelId = state.activeDatasourceModelId;
        const { suggestions } = state.conversationSuggestions?.[`${type}-${modelId}`];
        let { history } = state.conversationSuggestions[`${type}-${modelId}`];

        history.unshift(payload);
        history = Array.from(new Set(history).keys());
        const updated = {
          type,
          suggestions,
          modelId,
          history: history.slice(0, MAX_HISTORY_SUGGESTION_ITEMS),
        };
        commit('SET_CONVERSATION_SUGGESTIONS', updated);
        localStorage.setItem(`suggestions-history-${type}-${modelId}`, JSON.stringify({ history: updated.history, type: updated.type, modelId: updated.modelId }));
      } catch (e) {
        console.error(e); // eslint-disable-line
      }
    },
    async deleteSuggestionHistoryItem({ state, commit }, payload) {
      if (!payload) {
        return;
      }
      try {
        const type = state.activeDatasourceType;
        const modelId = state.activeDatasourceModelId;
        const { suggestions } = state.conversationSuggestions?.[`${type}-${modelId}`];
        let { history } = state.conversationSuggestions[`${type}-${modelId}`];

        history = history.filter((item) => item !== payload);
        history = Array.from(new Set(history).keys());
        const updated = {
          type,
          modelId,
          suggestions,
          history: history.slice(0, MAX_HISTORY_SUGGESTION_ITEMS),
        };
        commit('SET_CONVERSATION_SUGGESTIONS', updated);
        localStorage.setItem(`suggestions-history-${type}`, JSON.stringify({ history: updated.history, type: updated.type, modelId: updated.modelId }));
      } catch (e) {
        console.error(e); // eslint-disable-line
      }
    },
    async fetchProjects({ commit, dispatch }) {
      try {
        const response = await apiGetProjects();

        if (response.status === 200 && response.data) {
          commit('SET_PROJECTS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_projects'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_projects'), type: 'error' });
        return false;
      }
    },
    async fetchProjectData({ commit, dispatch }, payload) {
      try {
        const response = await apiGetProjectData(payload);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createProject({ dispatch }, payload) {
      const { name, tenants, authentication, cors } = payload;
      try {
        const response = await apiPostProject({ name, tenants, authentication, cors });

        if (response.status === 200 && response.data) {
          await dispatch('getTenant');
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_create_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_create_project'), type: 'error' });
        return false;
      }
    },
    async updateProject({ dispatch }, payload) {
      const { project_id, name, tenants, authentication, cors } = payload;
      try {
        const response = await apiPutProject({ project_id, name, tenants, authentication, cors });

        if (response.status === 200 && response.data) {
          await dispatch('getTenant');
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      }
    },
    async createProjectEndpoint({ dispatch }, payload) {
      const { project_id, method, path, group_name, steps } = payload;
      try {
        const response = await apiPostProjectEndpoint({ project_id, method, path, group_name, steps });

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectData', project_id);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.endpoints.failed_to_add'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.endpoints.failed_to_add'), type: 'error' });
        return false;
      }
    },
    async deleteProject({ dispatch, commit }, payload) {
      try {
        const response = await apiDeleteProject(payload);

        if (response.status === 200 && response.data) {
          commit('REMOVE_PROJECT', payload);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_remove_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_remove_project'), type: 'error' });
        return false;
      }
    },
    async deleteProjectVariable({ dispatch }, payload) {
      try {
        const response = await apiDeleteProjectVariable(payload);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectData', payload.project_id);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_remove_variable'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_remove_variable'), type: 'error' });
        return false;
      }
    },
    async deleteProjectSecret({ dispatch }, payload) {
      try {
        const response = await apiDeleteProjectSecret(payload);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectData', payload.project_id);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.secrets.failed_to_delete'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.secrets.failed_to_delete'), type: 'error' });
        return false;
      }
    },
    async deleteProjectEndpoint({ dispatch }, payload) {
      try {
        const response = await apiDeleteProjectEndpoint(payload);

        if (response.status === 200 && response.data) {
          await dispatch('fetchProjectData', payload.project_id);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.endpoints.failed_to_remove'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.endpoints.failed_to_remove'), type: 'error' });
        return false;
      }
    },
    async fetchUsers({ commit, dispatch }, payload) {
      try {
        const response = await apiGetUsers(payload);

        if (response.status === 200 && response.data) {
          commit('SET_USERS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_get_users'), type: 'error' });
        return false;
      }
    },
    async addUser({ dispatch }, payload) {
      try {
        const response = await apiPostAddUser(payload);

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.iam.failed_to_add_user'), type: 'error' });
        return false;
      }
    },
    async fetchModels({ commit, dispatch, state }, payload) {
      try {
        const { type } = payload;
        const response = await apiGetDataSourceModels(type);

        if (response.status === 200 && response.data) {
          commit('SET_MODELS', response.data);
          commit('SET_MODELS_FETCHED', true);
          if (!state.designTimeActiveDatasourceModelId && response.data.length) {
            commit('SET_DESIGN_TIME_ACTIVE_DATASOURCE_MODEL_ID', response.data[0].model_id);
          } else if (response.data && !response.data.some((m) => m.model_id === state.designTimeActiveDatasourceModelId)) {
            commit('SET_DESIGN_TIME_ACTIVE_DATASOURCE_MODEL_ID', response.data[0].model_id);
          }
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_models'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_models'), type: 'error' });
        return false;
      }
    },
    async fetchModel({ commit, dispatch }, payload) {
      try {
        const response = await apiGetProjectData(payload);

        if (response.status === 200 && response.data) {
          commit('SET_PROJECT_DATA', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_get_project_data'), type: 'error' });
        return false;
      }
    },
    async createModel({ dispatch }, payload) {
      const { name, tenants, authentication, cors } = payload;
      try {
        const response = await apiPostProject({ name, tenants, authentication, cors });

        if (response.status === 200 && response.data) {
          await dispatch('getTenant');
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_create_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_create_project'), type: 'error' });
        return false;
      }
    },
    async updateModel({ dispatch }, payload) {
      const { project_id, name, tenants, authentication, cors } = payload;
      try {
        const response = await apiPutProject({ project_id, name, tenants, authentication, cors });

        if (response.status === 200 && response.data) {
          await dispatch('getTenant');
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('projects.failed_to_update_project'), type: 'error' });
        return false;
      }
    },
    async fetchAddresses({ state, commit, dispatch }, payload) {
      if (!state.subscription.subscription_id) {
        return false;
      }
      try {
        const response = await apiGetAddresses(payload);

        if (response.status === 200 && response.data) {
          commit('SET_ADDRESSES', response.data);

          if (state.subscription.address_id && response.data.length && !response.data.some((a) => a.id === state.subscription.address_id)) {
            apiChangeSubscriptionAddress({ address_id: response.data[0].id });
          }
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('settings.address.failed_to_get_addresses'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('settings.address.failed_to_get_addresses'), type: 'error' });
        return false;
      }
    },
    async updateAddress({ dispatch, commit }, payload) {
      const { address_id, description, first_line, second_line, city, postal_code, region, country_code, status } = payload;
      try {
        const response = await apiPutAddress({ address_id, description, first_line, second_line, city, postal_code, region, country_code, status });

        if (response.status === 200 && response.data) {
          commit('UPDATE_ADDRESS', payload);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('settings.address.failed_to_update_address'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('settings.address.failed_to_update_address'), type: 'error' });
        return false;
      }
    },
    async setDefaultAddress({ dispatch, commit }, payload) {
      const { address_id } = payload;
      try {
        const response = await apiPutAddress({ address_id, custom_data: { lastDefaultDate: Date.now() } });

        if (response.status === 200 && response.data) {
          commit('UPDATE_ADDRESS', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('settings.address.failed_to_update_address'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('settings.address.failed_to_update_address'), type: 'error' });
        return false;
      }
    },
    async setTenantOrganisation({ dispatch, commit }, payload) {
      try {
        const response = await apiPutOrganisation({ org: payload });

        if (response.status === 200 && response.data) {
          await dispatch('getSelectedAccount');
          commit('SET_TENANT', response.data);
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('organisation.failed_to_change_organisation'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('organisation.failed_to_change_organisation'), type: 'error' });
        return false;
      }
    },
    async fetchLaunchRequest({ dispatch, state }) {
      try {
        const lastRequest = localStorage.getItem('launchRequest');
        if (Number.isInteger(parseInt(lastRequest, 10))) {
          const item = await idb.getById(state.org, `${state.activeDatasourceType}-${state.activeDatasourceModelId}`, parseInt(lastRequest, 10));
          const lastRequestTime = dayjs.unix(parseInt(lastRequest, 10) / 1000);
          if (item.length && dayjs().isSame(lastRequestTime, 'days')) {
            return;
          }
        }
        if (router.currentRoute.value.name !== 'conversation') {
          return;
        }
        const launchRequestItem = Object.values(state.conversation).find((i) => i.query.event === 'LaunchRequest');
        if (!launchRequestItem || (launchRequestItem?.query?.created && !dayjs().isSame(launchRequestItem.query.created, 'days'))) {
          const query = await dispatch('queryIntent', { event: 'LaunchRequest' });
          localStorage.setItem('launchRequest', query.query.created.toString());
        }
      } catch {} // eslint-disable-line
    },
    async fetchOrganisations({ dispatch }) {
      try {
        const response = await apiGetOrganisations();

        if (response.status === 200 && response.data) {
          return response.data;
        }
        dispatch('showToastMessage', { title: response.data.message || i18n.t('organisation.address.failed_to_fetch_organisation'), type: 'error' });
        return false;
      } catch (e) {
        dispatch('showToastMessage', { title: i18n.t('organisation.failed_to_fetch_organisation'), type: 'error' });
        return false;
      }
    },
    async fetchHelpPopularCategories({}) {
      return new Promise((resolve) => {
        resolve([
          { id: 1, text: 'Getting started' },
          { id: 2, text: 'Conversation' },
          { id: 3, text: 'Collection' },
          { id: 4, text: 'Profile' },
        ]);
      });
    },
    async fetchHelpPopularQuestions({}) {
      return new Promise((resolve) => {
        resolve(HelpQuestions);
      });
    },
    async fetchHelpQuestionsByCategory({ dispatch }, { categoryId }) {
      if (!categoryId) {
        return dispatch('fetchHelpPopularQuestions');
      }
      return new Promise((resolve) => {
        resolve(HelpQuestions.filter((item) => item.category === categoryId));
      });
    },
    async fetchHelpQuestion({}, { id }) {
      const res = await fetch(`/help/${id}.md`);
      if (res.status === 200) {
        return res.text();
      }
      return `\\
&nbsp;
## No Content Found!&nbsp;
\\
&nbsp;`;
    },
  },
  getters: {
    getActiveAccount(state) {
      if (state.data_sources && state.activeDatasourceAccountId && state.activeDatasourceType && state.data_sources?.[state.activeDatasourceType]) {
        let activeAccount = null;
        Object.keys(state.data_sources[state.activeDatasourceType]).forEach((email) => {
          const acc = Object.values(state.data_sources[state.activeDatasourceType][email]?.accounts || {})?.find((account) => account.account_id === state.activeDatasourceAccountId);
          if (acc) activeAccount = acc;
        });
        return activeAccount || {};
      }
      return {};
    },
    getActiveEmailAccount(state) {
      return state.activeDatasourceType ? state.data_sources[state.activeDatasourceType] : {};
    },
    getConversationList(state) {
      return state.conversation;
    },
    getConversationData: (state) => (id) => {
      return state.conversation[id].queryData;
    },
    getOnboardingComplete(state) {
      return state.flags?.oc === true;
    },
    getRegistrationComplete(state) {
      return state.flags?.rc === true;
    },
    getActiveDatasourceConversations(state) {
      return state.designTimeData?.[state.designTimeActiveDatasourceType]?.conversations || {};
    },
    getActiveDatasourceVariables(state) {
      return state.designTimeData?.[state.designTimeActiveDatasourceType]?.variables || {};
    },
    getActiveDatasourceVariablesArray(state, getters) {
      return Object.keys(getters.getActiveDatasourceVariables).map((v) => {
        return {
          name: v,
          ...getters.getActiveDatasourceVariables[v],
        };
      });
    },
    getActiveDatasourceDataTypes(state) {
      return state.designTimeData?.[state.designTimeActiveDatasourceType]?.entities || {};
    },
    getActiveDatasourceVariableList(state, getters) {
      return Object.keys(getters.getActiveDatasourceVariables)
        .map((v) => {
          return {
            label: v,
            value: v,
          };
        })
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    },
    getCollectionsQuestions(state) {
      return state.designTimeData[state.designTimeActiveDatasourceType]?.questions;
    },
    getSubscriptionIsExpired(state) {
      const cancellation_effective_date = state.subscription?.scheduled_change?.effective_at;
      const endDate = dayjs(cancellation_effective_date);
      return endDate.isBefore(dayjs(), 'day');
    },
    getSubscriptionWillBeCancelled(state) {
      const action = state.subscription?.scheduled_change?.action;
      return action === 'cancel';
    },
    getSubscriptionWillChange(state) {
      const action = state.subscription?.scheduled_change?.action;
      return action === 'downgrade';
    },
    getSubscriptionCancelled(state) {
      return SUBSCRIPTION_STATES.CANCELLED === state.subscription?.status;
    },
    getConversationSuggestions(state) {
      const type = state.activeDatasourceType;
      const modelId = state.activeDatasourceModelId;
      if (!state.conversationSuggestions?.[`${type}-${modelId}`]) {
        return {
          suggestions: [],
          history: [],
        };
      }
      const { suggestions, history } = state.conversationSuggestions[`${type}-${modelId}`];
      const suggestionsRandomized = suggestions;
      return {
        suggestions: suggestionsRandomized,
        history,
      };
    },
    getNoCodeProjectVariables(state) {
      return state.projectData?.[state.selectedIntentDefinition.project_id]?.variables;
    },
    getNoCodeEntities(state) {
      return state.projectData?.[state.selectedIntentDefinition.project_id]?.entities || {};
    },
    getDefaultAddress(state) {
      return state.addresses[0];
    },
  },
});
