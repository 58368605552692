<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('navigation.models') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton v-if="features.includes(FEATURES.ADMIN_MODELS)" icon="plus" :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <ListCard v-for="model in models" :key="model.id" class="flex-row mt-3" height-class="min-h-10 max-sm:py-2">
          <div class="flex flex-wrap truncate">
            <div class="text-14 max-sm:order-1 min-w-100 font-500 flex-shrink-0">{{ model.name }}</div>
            <div class="text-14 max-sm:w-full max-sm:order-3 font-500 truncate md:ml-10">{{ model.model_id }}</div>
            <div class="text-14 max-sm:order-2 font-500 ml-10">{{ model.org.join(', ') }}</div>
          </div>
          <div class="flex items-center flex-1">
            <div class="flex flex-row justify-end items-center ml-auto">
              <IconButton v-if="features.includes(FEATURES.ADMIN_MODELS)" icon="edit" class="mx-1 text-primary" @click="onEditClicked(model)" />
              <IconButton
                v-if="features.includes(FEATURES.ADMIN_MODELS_DELETE)"
                :disabled="models.length === 1"
                icon="delete"
                class="text-primary"
                @click="onDeleteClicked(model)"
                :text="$t('delete')"
              />
            </div>
          </div>
        </ListCard>
      </div>
    </div>
    <Modal v-if="loadingIntentsModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
    <Modal v-if="showDeletingModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('deleting') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { apiDeleteDataSourceModel } from '@/helpers/api';
import { FEATURES, VARIABLE_TYPE } from '@/constants';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import debounce from '@/helpers/debounce';
import AddEditDatasourceModel from '@/components/admin/AddEditDatasourceModel.vue';

export default {
  name: 'Models',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.modelsFetched && vm.$store.state.models.length === 0) {
        next('/admin/models?init=1');
      } else if (!vm.$store.state.modelsFetched) {
        vm.fetchData();
      }
    });
  },
  data() {
    return {
      VARIABLE_TYPE,
      FEATURES,
      variables: [],
      variableDetails: {},
      showDeletingModal: false,
      loadingIntentsModal: false,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'models', 'availableDataSources', 'features', 'org']),
    orgNameModelId() {
      return `${this.org}_${this.designTimeActiveDatasourceModelId}`;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchModels']),
    ...mapMutations(['ADD_MODEL']),
    onCreateClicked() {
      this.$showModal(AddEditDatasourceModel, null, { save: this.onModelSaved });
    },
    onEditClicked(model) {
      this.$showModal(
        AddEditDatasourceModel,
        {
          model,
        },
        {
          save: this.onModelSaved,
          update: this.onModelSaved,
        },
      );
    },
    onModelSaved(response) {
      this.ADD_MODEL(response);
    },
    onDeleteClicked(model) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('datastore.confirm_remove_model_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: async () => {
          await this.onDeleteConfirmClicked(model);
        },
      });
    },
    async onDeleteConfirmClicked(model) {
      this.showDeletingModal = true;
      const response = await apiDeleteDataSourceModel({ type: model.type, model_id: model.model_id });
      if (response.status === 200) {
        this.showToastMessage({ message: this.$t('datastore.model_removed_successfully'), type: 'success' });
        this.getModels();
      } else {
        this.showToastMessage({ title: this.$t('datastore.failed_to_remove_model'), type: 'error' });
      }
      this.showDeletingModal = false;
    },
    async getModels() {
      this.loadingIntentsModal = true;
      await this.fetchModels({ type: this.designTimeActiveDatasourceType });
      if (!this.models.length) {
        this.$showModal(AddEditDatasourceModel, { force: true }, { save: this.onModelSaved });
      }
      this.loadingIntentsModal = false;
    },
    // eslint-disable-next-line
    fetchData: debounce(async function () {
      this.getModels();
    }, 300),
  },
  mounted() {
    if (!this.models.length && this.$route.query.init) {
      this.$showModal(
        AddEditDatasourceModel,
        { force: true },
        {
          save: this.onModelSaved,
          close: () => {
            this.$router.push('/');
          },
        },
      );
      this.$router.replace({ query: null });
    }
  },
  watch: {
    orgNameModelId: {
      handler() {
        if (this.designTimeActiveDatasourceType && this.designTimeActiveDatasourceModelId) {
          this.fetchData();
        }
      },
      immediate: true,
    },
  },
};
</script>
