<template>
  <DefaultLayout class="relative">
    <div class="flex items-center justify-between absolute top-6 lg:left-28 right-0">
      <div class="flex justify-center items-center font-600 text-primary">
        <IconButton icon="arrow_forward" class="max-lg:ml-3 transform rotate-180 mr-3" @click="onBack" />
        <div class="text-primary text-20 font-600 h-12 flex items-center">{{ $route.params.intent }}</div>
      </div>
      <div class="ml-auto mr-4">
        <PillButton v-if="selectedIntentDirty" :loading="flowSaving" :disabled="flowSaving" :text="$t('save')" primary @click="onFlowSaveClicked" />
      </div>
    </div>
    <template v-if="loading">
      <div class="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center text-gray-600">{{ $t('flow.loading_definitions') }}</div>
    </template>
    <template v-else>
      <div id="flow-designer-wrapper" class="absolute left-0 lg:left-26 right-0 top-0 bottom-0" style="top: 60px">
        <FlowDesigner :steps="steps" ref="flowDesigner" @startClick="onStartClick" @addStepItem="onAddStepItem" @dirty="onFlowDesignerDirty" />
      </div>
    </template>
  </DefaultLayout>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import FlowDesigner from '@/components/flow/FlowDesigner';
import uuidv4 from '@/helpers/uuid';
import { apiPutConversationDefinition } from '@/helpers/api';
import StepEditor from '@/components/flow/step-editor/StepEditor';
import ConversationFlowConfigModal from '@/components/flow/ConversationFlowConfigModal';
import { generateStepName } from '@/helpers/flow';

const stepItemObject = {
  id: 'stepId',
  name: 'New Step Item',
  column: 1,
  order: 0,
  evaluate: [],
};

export default {
  name: 'Flow',
  components: { FlowDesigner },
  data() {
    return {
      loading: false,
      modal: null,
      flowSaving: false,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'bootstrapped', 'selectedIntentDefinition', 'selectedIntentDefinitionStepId', 'selectedIntentDirty']),
    showStepDataModal() {
      return this.stepData && !this.startStepSelected;
    },
    startStepSelected() {
      return this.stepData?.name === 'Start';
    },
    stepData() {
      return this.steps.find((item) => item.id === this.selectedIntentDefinitionStepId);
    },
    name() {
      return this.$route.params.intent;
    },
    steps() {
      if (!this.selectedIntentDefinition || !this.selectedIntentDefinition?.steps) {
        return [];
      }
      return JSON.parse(
        JSON.stringify([
          {
            id: '1',
            column: 0,
            order: 0,
            name: 'Start',
            type: 'conversation',
            transition: this.selectedIntentDefinition?.transition || null,
            request_params: this.selectedIntentDefinition?.request_params || null,
          },
          ...this.selectedIntentDefinition.steps,
        ]),
      );
    },
  },
  methods: {
    ...mapActions(['fetchDesignTimeData', 'showToastMessage', 'fetchConversationIntentDefinition', 'fetchFunctions', 'fetchModels']),
    ...mapMutations([
      'ADD_SELECTED_INTENT_DEFINITION_STEP',
      'SET_SELECTED_INTENT_DEFINITION',
      'SET_SELECTED_INTENT_DEFINITION_STEP',
      'REMOVE_SELECTED_INTENT_DEFINITION_STEP',
      'SET_SELECTED_INTENT_DEFINITION_PROP',
      'SET_SELECTED_INTENT_DIRTY',
      'SET_SELECTED_INTENT_DEFINITION_STEP_ID',
    ]),
    onBack() {
      this.$router.back();
    },
    onFlowDesignerDirty() {
      this.SET_SELECTED_INTENT_DIRTY(true);
    },
    async onFlowSaveClicked() {
      this.flowSaving = true;
      const { name, steps, transition } = this.selectedIntentDefinition;
      const response = await apiPutConversationDefinition({
        name,
        type: this.designTimeActiveDatasourceType,
        model_id: this.designTimeActiveDatasourceModelId,
        steps,
        transition,
      });
      if (response.status === 200) {
        this.showToastMessage({ message: this.$t('conversations.updated_successfully'), type: 'success' });
        this.SET_SELECTED_INTENT_DIRTY(false);
        this.SET_SELECTED_INTENT_DEFINITION_STEP_ID(null);
        this.flowSaving = false;
        return true;
      }
      this.showToastMessage({ title: this.$t('conversations.failed_to_update'), type: 'error' });
      this.flowSaving = false;
      return false;
    },
    onStepDeleteClicked(stepId) {
      this.REMOVE_SELECTED_INTENT_DEFINITION_STEP(stepId);
      this.SET_SELECTED_INTENT_DEFINITION_STEP_ID(null);
      this.onFlowSaveClicked();
    },
    onStepSaveClicked(step) {
      this.SET_SELECTED_INTENT_DEFINITION_STEP(step);
      this.onFlowSaveClicked();
    },
    async onFlowDefinitionSaveClicked(definition) {
      const { phrases, transition, suggestions, variables } = definition;
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'phrases', value: phrases });
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'transition', value: transition });
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'suggestions', value: suggestions });
      this.SET_SELECTED_INTENT_DEFINITION_PROP({ prop: 'variables', value: variables });
      if (this.selectedIntentDirty) {
        return this.onFlowSaveClicked();
      }
      return false;
    },
    onStartClick() {
      this.$showModal(ConversationFlowConfigModal, { onSave: this.onFlowDefinitionSaveClicked });
    },
    onAddStepItem(index) {
      const stepItem = JSON.parse(JSON.stringify(stepItemObject));
      stepItem.id = uuidv4();
      stepItem.column = index;
      stepItem.name = generateStepName(1, this.steps);
      const columnSteps = this.steps.filter((s) => s.column === index);
      stepItem.order = columnSteps.length;
      this.ADD_SELECTED_INTENT_DEFINITION_STEP(stepItem);
    },
  },
  created() {
    window.onbeforeunload = () => {
      if (this.selectedIntentDirty) {
        return this.$t('flow.alert_unsaved_changes');
      }
      return undefined;
    };
  },
  beforeUnmount() {
    this.SET_SELECTED_INTENT_DEFINITION(null);
  },
  beforeRouteLeave(to, from, next) {
    if (this.selectedIntentDirty && window) {
      // eslint-disable-next-line
      const isAggreedLeavingPage = window.confirm(this.$t('flow.alert_unsaved_changes'));
      next(isAggreedLeavingPage);
    } else {
      next();
    }
  },
  watch: {
    showStepDataModal() {
      this.modal?.close();

      if (this.showStepDataModal) {
        this.modal = this.$showModal(
          StepEditor,
          {
            stepData: this.stepData,
          },
          {
            save: this.onStepSaveClicked,
            delete: this.onStepDeleteClicked,
          },
        );
      }
    },
    startStepSelected() {
      if (this.startStepSelected === false && this.selectedIntentDirty) {
        this.onFlowSaveClicked();
      }
    },
    bootstrapped: {
      async handler() {
        if (this.designTimeActiveDatasourceType) {
          try {
            this.loading = true;
            await this.fetchModels({ type: this.designTimeActiveDatasourceType });
            await this.fetchDesignTimeData();
            this.fetchFunctions();
            const response = await this.fetchConversationIntentDefinition({ type: this.designTimeActiveDatasourceType, name: this.name });
            if (response) {
              this.SET_SELECTED_INTENT_DEFINITION(response);
            } else {
              this.showToastMessage({ title: this.$t('conversations.failed_to_fetch'), type: 'error' });
            }
            this.loading = false;
          } catch (e) {
            this.showToastMessage({ title: this.$t('conversations.failed_to_fetch'), type: 'error' });
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
</style>
