<template>
  <div class="pl-4 pr-4 pt-4 mb-5 md:pt-0 md:mb-10 md:pl-0 md:pr-10 flex flex-col">
    <div class="flex justify-between items-center pb-4">
      <div class="text-14">{{ $t('datastore.active_data_sources', { count: datasourceLength }) }}</div>
      <PillButton :text="$t('datastore.add_connection')" icon="plus" primary @click="onAddClick" />
    </div>
    <div class="w-full pl-1">
      <div class="w-full flex flex-wrap">
        <div v-for="(datasource, index) in dataSources" :key="index" class="w-full lg:w-1/2" :class="{ 'lg:pr-2': index % 2 === 0, 'lg:pl-2': index % 2 === 1 }">
          <CollapseCard :value="false" :showIcon="false" :scroll="false" class="shadow-card mb-4">
            <template #header>
              <div class="flex justify-between w-full flex-wrap -mt-3">
                <div class="flex items-center mt-3">
                  <img :src="datasource.icon" class="w-8 h-8 mr-2" alt="datasource.name" />
                  <div class="flex items-center flex-wrap">
                    <span class="font-600 mr-2">{{ datasource.name }}</span>
                  </div>
                </div>

                <div class="flex items-center justify-between flex-grow mt-3">
                  <div class="flex">
                    <Badge v-if="datasource.active" :text="$t('datastore.active')" success class="ml-2" />
                    <Badge v-else-if="datasource.revoked" :text="$t('datastore.revoked')" warn class="ml-2" />
                    <Badge v-else :text="$t('datastore.inactive')" warn class="ml-2" />
                  </div>
                  <div>
                    <PillButton v-if="datasource.revoked" :text="$t('datastore.connect')" primary class="ml-2" @click.stop="onReconnect(datasource.type)" />
                    <PillButton v-if="!datasource.active && !datasource.revoked" :text="$t('datastore.activate')" primary class="ml-2" @click.stop="onReconnect(datasource.type)" />
                    <PillButton
                      v-if="!datasource.revoked && availableDataSources[datasource.type].auth_type === 'oauth2'"
                      error
                      :text="$t('datastore.revoke')"
                      class="ml-2"
                      @click.stop="onRevokeClick(datasource)"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #content>
              <div>
                <div v-if="availableDataSources[datasource.type].accounts" class="px-4 py-2 w-full text-13">{{ datasource.account_email }}</div>
                <div v-if="!datasource.revoked">
                  <template v-if="datasource.accounts">
                    <div v-for="(account, accountIndex) in datasource.accounts" :key="accountIndex" class="flex px-4 py-2 border-t border-solid border-gray-200 flex-grow">
                      <div class="flex-grow pt-1 flex items-center">
                        {{ account.name }} <Badge v-if="datasource.selected_account === account.account_id" info :text="$t('datastore.selected_account')" class="ml-2" />
                      </div>
                      <IconButton v-if="availableDataSources[datasource.type].accounts" icon="delete" class="text-primary" @click="onDeleteAccountClick(account, datasource)" />
                    </div>
                  </template>
                  <template v-else>
                    <div class="pt-3 px-3">{{ $t('datastore.no_account_selected') }}</div>
                  </template>
                  <div v-if="availableDataSources[datasource.type].accounts" class="flex px-4 py-3 border-t border-solid border-gray-200 flex-grow">
                    <div class="flex-grow pt-1"></div>
                    <PillButton v-if="availableDataSources[datasource.type].accounts" class="ml-3" outlined :text="$t('datastore.add_account')" @click="onAddAccountClick(datasource)" />
                  </div>
                </div>
              </div>
            </template>
          </CollapseCard>
        </div>
      </div>

      <Modal v-if="revokeAccessLoading">
        <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
          <div>{{ $t('datastore.revoking_access') }}</div>
          <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DeleteModal from '@/components/shared-modals/DeleteModal.vue';
import PropertySelector from '@/components/datasource/PropertySelector.vue';
import AddDataSourceModal from '@/components/datasource/AddDataSourceModal.vue';

export default {
  name: 'DataSources',
  data() {
    return {
      revokeAccessLoading: false,
      selectedDatasource: null,
      loading: false,
    };
  },
  mounted() {
    const { type } = this.$route.query;

    if (type) {
      const ds = this.data_sources?.[type] || {};
      const datasourceId = Object.keys(ds)?.[0];
      this.$router.replace({ query: null });

      // If the user has an active account then do not open account selector
      let showModal = true;
      Object.keys(this.data_sources[type]).forEach((item) => {
        if (item.accounts && Object.keys(item.accounts).length && Object.values(item.accounts).some((acc) => acc.active)) {
          showModal = false;
        }
      });
      if (showModal) {
        this.onAddAccountClick({ datasource_id: datasourceId, type });
      }
    }
  },
  computed: {
    ...mapState(['data_sources', 'availableDataSources']),
    dataSources() {
      const dataSources = [];
      if (this.data_sources && typeof this.data_sources === 'object' && Object.keys(this.data_sources)?.length) {
        Object.entries(this.data_sources)?.forEach(([type, item]) => {
          Object.entries(item)?.forEach(([email, emailItem]) => {
            dataSources.push({
              name: this.availableDataSources[type].name,
              type,
              revoked: emailItem.revoked,
              active: emailItem.active,
              account_email: email,
              datasource_id: email,
              selected_account: emailItem.selected_account,
              user: emailItem.user,
              icon: this.availableDataSources[type].logo,
              accounts: emailItem.accounts || {},
            });
          });
        });
      }
      return dataSources;
    },
    datasourceLength() {
      return Object.keys(this.data_sources).length;
    },
  },
  methods: {
    ...mapActions(['revokeDataSourceAccess', 'showToastMessage', 'removeAccountFromDatasource', 'setDatasourceDefaults', 'oAuthRedirect']),
    onReconnect(type) {
      this.oAuthRedirect({ type });
    },
    onDeleteAccountClick(account, datasource) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('datastore.confirm_remove_account_message'),
        onConfirm: () =>
          this.removeAccountFromDatasource({
            type: datasource.type,
            datasource_id: datasource.datasource_id,
            account_id: account.account_id,
          }),
      });
    },
    onAddAccountClick(datasource) {
      this.$showModal(PropertySelector, {
        datasourceId: datasource.datasource_id,
        type: datasource.type,
      });
    },
    onRevokeClick(datasource) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('datastore.confirm_revoke_access_message'),
        info: null,
        onConfirm: () => this.revokeAdminAccess(datasource),
      });
    },
    async revokeAdminAccess(datasource) {
      try {
        this.revokeAccessLoading = true;
        await this.revokeDataSourceAccess({ type: datasource.type, datasource_id: datasource.datasource_id });
      } catch (e) {
        this.showToastMessage({ title: this.$t('datastore.failed_to_revoke_account'), type: 'error' });
      }
      this.revokeAccessLoading = false;
    },
    onAddClick() {
      this.$showModal(AddDataSourceModal);
    },
  },
};
</script>
