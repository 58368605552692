<template>
  <div v-if="bootstrapped" class="h-screen flex flex-col items-center justify-start" style="background-color: #faf9ff">
    <div class="z-0 flex w-full">
      <div
        class="left-bar absolute z-1 min-h-full max-h-screen overflow-auto left-0 top-0 bottom-0 hidden lg:flex lg:flex-col transition-width ease-in duration-150 py-4 px-4"
        :class="{ 'lg:w-sidebar--opened': leftMenuOpened, 'lg:w-sidebar': !leftMenuOpened }"
      >
        <Navigation v-model:open="leftMenuOpened" />
      </div>
      <div
        class="top-bar fixed z-10 top-0 right-0 transition-all ease-in duration-150 left-0"
        :class="{ 'lg:left-top-bar--opened': leftMenuOpened, 'lg:left-top-bar': !leftMenuOpened }"
        style="background-color: #f5f3fe"
      >
        <div class="w-full pt-3 pb-3 absolute flex items-center z-0 px-6 min-h-16" style="background-color: #f6f4fe">
          <IconButton icon="menu" class="lg:hidden text-primary -ml-4" @click="subMenuOpened = true" />
          <div class="text-17 flex justify-center items-center font-600 text-primary">
            <span class="text-20"> {{ header }} </span>
          </div>
          <div class="flex ml-auto items-center">
            <OrganisationSelect v-if="showOrganisation" class="org-select mr-2 sm:mr-4" outlined />
            <Icon name="faq" class="w-5 h-5 cursor-pointer mr-4" @click="SET_SHOW_SIDE_PANEL(true)" />
            <Icon name="notification" class="w-6 h-6 cursor-pointer" @click="showNotificationPopup = true" v-outside-click="closeNotificationPopup" />

            <Notifications v-if="showNotificationPopup" />
          </div>
        </div>
      </div>
      <div class="main-content pt-14 lg:pt-4 w-full flex flex-col h-screen" :class="{ 'lg:pl-main-content--opened': leftMenuOpened, 'lg:pl-main-content': !leftMenuOpened }">
        <div class="flex-grow flex flex-row overflow-auto relative max-lg:z-1">
          <div class="bg-black opacity-25 absolute inset-0 z-1 lg:hidden" :class="{ hidden: !subMenuOpened }" @click="subMenuOpened = false"></div>
          <div
            class="w-280 lg:w-280 flex-shrink-0 lg:-ml-4 max-lg:absolute max-lg:z-1 max-lg:min-h-full max-lg:shadow-xl transition-all ease-in duration-200"
            :class="{ 'max-lg:left-0': subMenuOpened, 'max-lg:-left-[280px]': !subMenuOpened }"
            style="background-color: rgb(250, 249, 255)"
          >
            <div class="opacity-0 text-primary text-20 lg:text-24 font-600 h-15 flex items-center pl-5 lg:pl-9">
              <IconButton v-if="showBackButton" icon="chevron_left" size="6" class="-ml-4" @click="back" /> {{ header }}
            </div>
            <div class="mt-0 lg:mt-5 pl-4 lg:pl-9 pr-4 mb-6 max-lg:mt-6"><component :is="headerComponent" /></div>
            <ul class="mt-0 lg:mt-5 pl-4 lg:pl-9 pr-4 text-16 select-none">
              <template v-for="(item, index) in userMenuItems">
                <li v-if="item.children" :key="`c-${index}`" class="my-2 cursor-pointer">
                  <div
                    class="w-full py-3 cursor-pointer pl-5 rounded-8 hover:bg-gray-200 flex justify-between"
                    @click.stop="item.open = !item.open"
                    :class="{ 'bg-gray-300 text-black hover:bg-gray-200': isLinkActive(item) || item.open }"
                  >
                    {{ item.label }}
                    <Icon v-if="!item.alwaysOpen" name="expand_more" class="w-6 h-6 mr-2" />
                  </div>
                  <div
                    class="w-full overflow-hidden relative transition-all duration-300 ease-in-out max-h-0"
                    :class="{ 'max-h-500': item.open || item.alwaysOpen, 'opacity-0': !item.open, 'opacity-100': item.open || item.alwaysOpen }"
                  >
                    <div class="w-full transition-all duration-300 ease-in-out" :style="{ transform: item.open || item.alwaysOpen ? 'translateY(0)' : 'translateY(-100%)' }">
                      <ul>
                        <router-link
                          v-for="(childItem, childIndex) in item.children"
                          :key="childIndex"
                          tag="li"
                          :to="childItem.to"
                          active-class="bg-primary text-white hover:bg-primary hover:bg-opacity-[90%]"
                          class="pl-10 my-2 py-3 cursor-pointer rounded-8 hover:bg-gray-200"
                        >
                          {{ childItem.label }}
                        </router-link>
                      </ul>
                    </div>
                  </div>
                </li>
                <li
                  v-else
                  :key="index"
                  @click="goTo(item)"
                  :class="{ 'bg-primary text-white hover:bg-primary hover:bg-opacity-[90%]': isLinkActive(item) }"
                  class="pl-5 my-2 py-3 cursor-pointer hover:bg-gray-200 rounded-8"
                >
                  {{ item.label }}
                </li>
              </template>
            </ul>
          </div>
          <div class="w-full flex-grow overflow-hidden flex flex-col md:pl-3 max-lg:z-0">
            <div class="w-full flex-grow overflow-hidden rounded-tl-20 mt-3 lg:mt-16 bg-white shadow-analysisCard px-2 py-2 lg:py-10 lg:pb-0 lg:pl-10" style="background-color: #fcfcff">
              <div class="w-full h-full overflow-auto">
                <router-view />
                <slot />
              </div>
            </div>
          </div>
        </div>

        <div class="h-14 inline lg:hidden z-0 bottom-navigation">
          <BottomNavigation />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Navigation from '@/components/ui/Navigation';
import BottomNavigation from '@/components/ui/BottomNavigation';
import NoCodeHeader from '@/components/header/NoCodeHeader';
import { FEATURES } from '@/constants';
import OrganisationSelect from '@/components/datasource/OrganisationSelect.vue';
import Quota from '@/components/ui/Quota.vue';
import AccountSelectDropdown from '@/components/datasource/AccountSelectDropdown.vue';
import Notifications from '@/components/ui/Notifications.vue';
import AdminHeader from '@/components/header/AdminHeader.vue';

export default {
  name: 'SideLayout',
  components: { Notifications, AccountSelectDropdown, Quota, OrganisationSelect, BottomNavigation, Navigation },
  data() {
    return {
      leftMenuOpened: false,
      subMenuOpened: false,
      showNotificationPopup: false,
      selectedNavItem: '',
      menuItems: [],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(['bootstrapped', 'user', 'features', 'selectedIntentDefinition', 'locale']),
    showOrganisation() {
      if (this.$route.fullPath.startsWith('/no-code')) return false;
      return true;
    },
    showBackButton() {
      return this.$route.fullPath.startsWith('/admin') || this.$route.fullPath.startsWith('/iam/');
    },
    headerComponent() {
      const [firstMatched] = this.$route.matched;

      if (this.$route.path.startsWith('/admin')) {
        return AdminHeader;
      }
      if (firstMatched) {
        if (firstMatched.meta.header === 'no_code') {
          return NoCodeHeader;
        }
      }
      return null;
    },
    header() {
      const [firstMatched] = this.$route.matched;

      if (firstMatched) {
        if (firstMatched.meta.header === 'datasources') {
          return this.$t('navigation.data_source_management');
        }
        if (firstMatched.meta.header === 'iam') {
          return this.$t('navigation.iam_dashboard');
        }
        if (firstMatched.meta.header === 'no_code') {
          return this.$t('navigation.projects');
        }
      }
      if (this.$route.path.startsWith('/admin')) {
        return this.$t('navigation.admin');
      }

      return this.$t('navigation.settings');
    },
    userMenuItems() {
      return this.menuItems.filter((item) => {
        if (item.restrictTo) {
          return this.features.includes(item.restrictTo);
        }
        return true;
      });
    },
  },
  methods: {
    ...mapMutations(['SET_SHOW_SIDE_PANEL']),
    closeNotificationPopup() {
      this.showNotificationPopup = false;
    },
    back() {
      if (this.$route.fullPath.startsWith('/admin') || this.$route.fullPath.startsWith('/iam')) {
        this.$router.push('/admin');
      }
    },
    initialize() {
      this.subMenuOpened = false;
      this.createMenuItems();
      this.menuItems.forEach((item) => {
        if (item.collapsable === false) {
          item.open = true;
        } else {
          item.open = this.isLinkActive(item);
        }
      });

      this.menuItems.forEach((item) => {
        if (item.to === this.$route.fullPath) {
          this.selectedNavItem = item.label;
        }
        if (item.children) {
          item.children.forEach((child) => {
            if (child.to === this.$route.fullPath) {
              this.selectedNavItem = child.label;
            }
          });
        }
      });
    },
    createMenuItems() {
      const { fullPath, params = {} } = this.$route;
      let menuItems = [];

      if (fullPath.startsWith('/admin/')) {
        menuItems = [
          { label: this.$t('navigation.flows'), to: { name: 'flows-admin', params }, restrictTo: FEATURES.ADMIN_CONVERSATIONS },
          { label: this.$t('conversations.ai_fine_tuning'), to: { name: 'fine-tune-admin', params }, restrictTo: FEATURES.ADMIN_CONVERSATIONS },
          { label: this.$t('conversations.suggestions'), to: { name: 'suggestions-admin', params }, restrictTo: FEATURES.ADMIN_CONVERSATIONS },
          { label: this.$t('navigation.variables'), to: { name: 'variables-admin', params } },
          { label: this.$t('navigation.data_types'), to: { name: 'data-types-admin', params } },
          { label: this.$t('navigation.models'), to: { name: 'models-admin', params }, restrictTo: FEATURES.ADMIN_MODELS },
          { label: this.$t('navigation.publishing'), to: { name: 'publishing-admin', params }, restrictTo: FEATURES.ADMIN_PUBLISH },
        ];
      } else if (fullPath.startsWith('/no-code/')) {
        menuItems = [
          { label: this.$t('navigation.endpoints'), name: 'no-code-endpoints' },
          { label: this.$t('navigation.variables'), name: 'no-code-variables' },
          { label: this.$t('navigation.secrets'), name: 'no-code-secrets' },
          { label: this.$t('navigation.iam'), name: 'no-code-iam' },
          { label: this.$t('navigation.event_tracking'), to: '#' },
          { label: this.$t('navigation.email_templates'), name: 'no-code-email-templates' },
          { label: this.$t('navigation.authentication'), name: 'no-code-authentication' },
          { label: this.$t('navigation.database'), name: 'no-code-database' },
          { label: this.$t('navigation.settings'), name: 'no-code-settings' },
        ];
      } else if (fullPath.startsWith('/iam/')) {
        menuItems = [{ label: this.$t('navigation.user_management'), to: '/iam/user-management', restrictTo: FEATURES.ADMIN_USER_MANAGEMENT }];
      } else {
        menuItems = [
          { label: this.$t('navigation.personal_settings'), to: '/user/settings', restrictTo: FEATURES.SETTINGS_PROFILE },
          { label: this.$t('navigation.security'), to: '/user/security', restrictTo: FEATURES.SETTINGS_PROFILE },
          { label: this.$t('navigation.subscription'), to: '/user/subscriptions', restrictTo: FEATURES.SETTINGS_SUBSCRIPTION },
          { label: this.$t('navigation.data_sources'), to: '/user/datasources', restrictTo: FEATURES.SETTINGS_DATASOURCE },
        ];
      }

      this.menuItems = menuItems;
    },
    goTo(item) {
      if (typeof item.to === 'object') {
        this.$router.push(item.to);
        return;
      }
      if (Object.keys(this.$route.params).length) {
        this.$router.push({ name: item.name, params: { id: this.$route.params.id } });
        return;
      }
      this.$router.push({ path: `${item.to}` });
    },
    isLinkActive(item) {
      let active = false;
      if (this.$route.matched.some((route) => route.path === item.to)) {
        active = true;
      } else if (item.name === this.$route.name || item?.to?.name === this.$route.name) {
        active = true;
      } else if (item.to === this.$route.fullPath) {
        active = true;
      } else if (this.$route.fullPath.startsWith(item.to)) {
        active = true;
      }
      if (item.children) {
        item.children.forEach((child) => {
          if (child.to === this.$route.fullPath) {
            active = true;
          }
        });
      }
      return active;
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.initialize();
      },
    },
    '$route.hash': {
      handler() {
        this.initialize();
      },
    },
    locale() {
      this.initialize();
    },
  },
};
</script>
