<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('projects.database.title') }}</div>
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div class="w-full sm:py-3 grid gap-4 grid-cols-collection-mobile sm:grid-cols-collection">
          <ListCard class="h-20 py-2 bg-white rounded-20 cursor-pointer" v-for="database in databases" :key="database.name">
            <div class="flex w-full h-full items-center">
              <div class="w-11 h-11">
                <img class="h-11" :src="database.icon" />
              </div>
              <div>
                <div class="ml-2 font-500">{{ database.name }}</div>
              </div>
            </div>
          </ListCard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dynamodbicon from '@/assets/png/dynamodb.png';
import cloud_firestore from '@/assets/png/cloud_firestore.png';

export default {
  name: 'Database',
  data() {
    return {
      databases: [
        {
          name: 'DynamoDB',
          icon: dynamodbicon,
        },
        {
          name: 'Cloud Firestore',
          icon: cloud_firestore,
        },
      ],
    };
  },
};
</script>
