<template>
  <div v-if="favouritePhrases.length" class="w-full rounded-20 h-[420px] overflow-auto pt-3">
    <Accordion v-model="favouriteAccordionIndex" @update:modelValue="onAccordionInput">
      <AccordionItem
        v-for="(phrase, index) in favouritePhrases"
        :key="index"
        :index="index"
        class="rounded-10 my-2 bg-white bg-opacity-40"
        :class="{ 'mt-0': index === 0, 'mb-0': index + 1 === favouritePhrases.length }"
      >
        <template #title>
          <div class="flex items-center px-3 py-[10px]" :class="{ 'border-b border-stroke': favouriteAccordionIndex === index }">
            <Icon v-if="index < 5 && tenantFavouriteQuestions.includes(phrase)" name="history" class="w-4 h-4 text-gray-600 flex-shrink-0" />
            <Icon v-else name="insights" class="w-4 h-4 text-primary text-opacity-90 flex-shrink-0" />
            <span class="ml-2">{{ phrase }}</span>

            <Icon name="expand_more" class="w-4 h-4 transition-all ease-in duration-100 opacity-50 ml-auto flex-shrink-0" :class="{ '-rotate-90': favouriteAccordionIndex !== index }" />
          </div>
        </template>
        <template #default>
          <div class="h-250">
            <div v-if="isLoading(phrase)" class="flex-grow flex items-center justify-center w-full h-full">
              <Spinner class="w-8" />
            </div>
            <CompactAnalysisCard
              v-if="getQuestion(phrase) && (getQuestion(phrase).result || getQuestion(phrase).error)"
              :error="getQuestion(phrase).error"
              :error-message="getQuestion(phrase).errorMessage"
              :collectionCard="{}"
              :type="activeDatasourceType"
              :result="getQuestion(phrase).result"
              @detail="onDetail($event, getQuestion(phrase))"
            ></CompactAnalysisCard>
          </div>
        </template>
      </AccordionItem>
    </Accordion>
  </div>
  <div v-else>
    <div class="p-4">{{ $t('dashboard.no_questions') }}</div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AccordionItem from '@/components/ui/AccordionItem.vue';
import Accordion from '@/components/ui/Accordion.vue';
import AnalysisCardExportModal from '@/components/conversation/AnalysisCardExportModal.vue';
import idb from '@/helpers/idb';

export default {
  name: 'FavouriteQuestions',
  components: { Accordion, AccordionItem },
  data() {
    return {
      favouriteAccordionIndex: null,
      favouriteQuestions: [],
      tenantFavouriteQuestions: [],
      favouriteQuestionResults: [],
    };
  },
  computed: {
    ...mapState(['activeDatasourceType', 'conversationSuggestions', 'org', 'activeDatasourceModelId']),
    favouritePhrases() {
      let arr = [...this.tenantFavouriteQuestions];
      if (this.activeDatasourceType && this.activeDatasourceType in this.conversationSuggestions) {
        arr = [...new Set([...arr, ...this.conversationSuggestions[this.activeDatasourceType].suggestions.slice(0, 15)])];
      }
      return arr.slice(0, 10);
    },
  },
  created() {
    this.getTenantFavourites();
  },
  methods: {
    ...mapActions(['query']),
    setFavouriteAccordionIndex(index) {
      this.favouriteAccordionIndex = index;
    },
    async getTenantFavourites() {
      this.favouriteAccordionIndex = null;
      const dbQuestions = await idb.getFavouritesQuestions(this.org, `${this.activeDatasourceType}-${this.activeDatasourceModelId}`);
      this.tenantFavouriteQuestions = dbQuestions.map((q) => q.phrase);
    },
    getQuestion(phrase) {
      return this.favouriteQuestionResults.find((i) => i.question === phrase);
    },
    isLoading(phrase) {
      const item = this.favouriteQuestionResults.find((i) => i.question === phrase);
      if (item) {
        return item.loading;
      }
      return false;
    },
    async onAccordionInput(e) {
      if (e === null) return;

      const phrase = this.favouritePhrases[e];
      const item = this.getQuestion(phrase);
      if (!item || item.result || item.loading) return;

      item.loading = true;
      item.error = false;
      item.errorMessage = null;
      const response = await this.query({ phrase });
      item.loading = false;

      if (response?.data?.responses?.[0]?.card) {
        item.result = response.data.responses[0].card;
      } else if (response?.data?.responses?.[0]?.text) {
        item.result = { text: response?.data?.responses?.[0]?.text };
      } else {
        item.error = true;
        item.errorMessage = response?.data?.message;
      }
    },
    onDetail(event, question) {
      this.$showModal(AnalysisCardExportModal, {
        'datasource-type': this.activeDatasourceType,
        'export-chart-type': event.selectedChartType,
        result: question.result,
        'show-export-modal': true,
      });
    },
  },
  watch: {
    favouritePhrases: {
      handler() {
        this.favouritePhrases.forEach((phrase) => {
          if (!this.favouriteQuestionResults.some((item) => item.question === phrase)) {
            this.favouriteQuestionResults.push({ question: phrase, loading: false, error: false, errorMessage: null, result: null });
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
